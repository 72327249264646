<template>
  <div>
    <div>
      <i style="cursor:pointer" @click="goBack" class="iconfont icon-return"></i>
    </div>
      <div class="main">
          <div class="title">
              <h4>プロジェクト情報</h4>
          </div>
          <div class="form">
                <el-form label-position="left" label-width="110px" :model="newProject" :rules="projectRules">
                    <!-- 图标 -->
                    <el-form-item  label="ヘッダー">
                        <el-upload
                        class="avatar-uploader"
                        action="no"
                        accept=".jpg, .jpeg, .png"
                        :http-request="upLoadIcon"
                        :show-file-list="false">
                            <img v-if="newProject.imageUrl" :src="newProject.imageUrl" style="width: 100%">
                            <i class="el-icon-camera avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <!-- 漫画类型 -->
                    <el-form-item label="漫画タイプ" prop="cartoonType">
                        <el-radio-group v-model="newProject.cartoonType">
                            <el-radio :label="1">連載</el-radio>
                            <el-radio :label="2">読切</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 漫画名 -->
                     <el-form-item label="漫画タイトル" prop="name" :rules="[{ required: true, message: '漫画タイトルを入力して下さい', trigger: 'blur' }]">
                        <el-input placeholder="漫画タイトルを入力して下さい" v-model="newProject.name" style="width:35%"></el-input>
                    </el-form-item>
                    <!-- 分类 -->
                     <el-form-item label="ジャンル" prop="bval" :rules="[{ required: true, message: 'ジャンルを選択下さい', trigger: 'change' }]">
                        <el-select size="medium" multiple  v-model="newProject.bval" placeholder="選択下さい" style="width:35%">
                            <el-option
                            v-for="item in newProject.btype"
                            :key="item.id"
                            :label="item.typeb"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 创作/随想 -->
                    <el-form-item label="創作/エッセイ" prop="isMake">
                        <el-radio-group v-model="newProject.isMake">
                            <el-radio :label="1">創作</el-radio>
                            <el-radio :label="2">エッセイ</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="概要" prop="outline" :rules="[{ required: true, message: '概要を入力下さい', trigger: 'blur' }]">
                        <el-input
							type="textarea"
							placeholder="概要を入力して下さい"
							v-model="newProject.outline"
							maxlength="300"
							show-word-limit
							:autosize="{ minRows: 3, maxRows: 4}"
                            style="width:70%"
							>
						</el-input>
                    </el-form-item>
                    <!-- 企划书 -->
                    <el-form-item label="企画書">
                        <el-upload
                        class="upload-demo"
                        action="no"
                        :http-request="upLoadBook"
                        :show-file-list="false">
                        <el-button size="small" type="primary">クリックしてアップロード</el-button>
                        <div slot="tip" class="el-upload__tip">アップロードできるのは5G以下のファイルです。</div>
                        <div v-if="newProject.fileName">{{ newProject.fileName }}</div>
                        </el-upload>
                    </el-form-item>
                    <!-- 外部公开 -->
                     <el-form-item v-show="false" label="外部公開" prop="isOvert">
                        <el-radio-group v-model="newProject.isOvert">
                            <el-radio :label="1">公開</el-radio>
                            <el-radio :label="2">非公開</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 制作种类 -->
                     <el-form-item label="制作種類" prop="makeType">
                        <el-radio-group v-model="newProject.makeType">
                            <el-radio :label="1">商業制作</el-radio>
                            <el-radio :label="2">自主制作</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
          </div>
          <div class="submit">
              <span @click="confirm">確認画面</span>
             
          </div>
          <el-dialog title="プロジェクト情報" :visible.sync="dialogFormVisible">
           <el-descriptions title="" :column="1" border :label-style="LS">
				<el-descriptions-item label="ヘッダー">
                    <img v-if="newProject.imageUrl" :src="newProject.imageUrl" style="width: 200px; height: 100%;">
                </el-descriptions-item>
				<el-descriptions-item label="漫画タイプ"> {{ newProject.cartoonType == 1 ? '連載' : '読切'}} </el-descriptions-item>
				<el-descriptions-item label="漫画タイトル"> {{ newProject.name }} </el-descriptions-item>
				<el-descriptions-item label="ジャンル">
					<div v-for="(item,index) in newProject.bval" :key="index"> 
                        <span> {{ filterClassAType(item) }}</span>
                     </div>
				</el-descriptions-item>
                <el-descriptions-item label="創作/エッセイ">{{ newProject.isMake == 1 ? '創作' : 'エッセイ'}}</el-descriptions-item>
                <el-descriptions-item label="概要"><div v-html="outline"></div></el-descriptions-item>
				<el-descriptions-item v-if="newProject.fileName" label="企画書"> {{newProject.fileName }} </el-descriptions-item>
                <!-- <el-descriptions-item label="外部公開">{{ newProject.isOvert == 1 ? '公開' : '非公開'}}</el-descriptions-item> -->
                <el-descriptions-item label="制作種類">{{ newProject.makeType == 1 ? '商業制作' : '自主制作'}}</el-descriptions-item>
			</el-descriptions>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取　消</el-button>
                <el-button type="primary" @click="submitData">確　定</el-button>
            </div>
        </el-dialog>
      </div>
  </div>
</template>

<script>
import service from '@/service'
export default {
    name: "newProject",
    data() {
        return {
            newProject: {
                imageUrl: '',
                cartoonType: 1,
                name: '',
                isMake: 1,
                btype: [],
                bval:[],
                outline: '',
                isOvert: 1,
                makeType: 1,
                matter: '',
                bookName: '',
                iconName:'',
                fileName:""
            },
            outline: '',
            dialogFormVisible: false,
            isEdit: false,
            projectId: '',
            projectInfo: {},
            projectRules: {},
            LS: {
				'color': '#000',
				'font-weight':'700',
				'text-align': 'right',
				'width': '180px',
				'word-break': 'keep-all'
			},
        }
    },
    mounted() {
       this.getTypeData();
      
    //    if(this.$route.query.params) {
    //        let data = JSON.parse(this.$route.query.params);
    //        this.projectId = data.id;
    //        this.newProject.name = data.title;
    //        this.newProject.cartoonType = data.type;
    //        this.newProject.bval = data.typeb.split(",").map(Number);
    //        this.newProject.outline = data.content
    //        this.newProject.isOvert = data.public;
    //        this.newProject.makeType = data.kind;
    //        this.newProject.isMake = data.creativecap;
    //    }
        if(this.$route.query.pid) {
            this.projectId = this.$route.query.pid
            this.getProjectInfo(this.projectId)
        }
       if(this.$route.query.isEdit) {
            this.isEdit = true;
       }
    },
    methods: {
        changeLine(str){
            console.log(str)
            let aaa = str.replace(/\n|\r\n/g, '<br>')
            console.log(aaa,"====")
		    return str.replace(/\n/g, "<br/>")
	    },
        getProjectInfo(data) {
            service.personal.getProjectInfo({ projectid: data }).then((res) => {
            this.projectInfo = res.data
            this.newProject.name = res.data.title
            this.newProject.outline = res.data.content.replace(/<br\/>/g,'\n')
            this.newProject.cartoonType = res.data.type;
            this.newProject.isOvert = res.data.public;
            this.newProject.bval = res.data.typeb.split(",").map(Number)
            this.newProject.imageUrl=`/userupload/${ res.data.icon }`
            let index = this.projectInfo.proposals.indexOf('/')
            let name = this.projectInfo.proposals.substr(index+1)
            this.newProject.fileName = name
            
       
       
        });
    },
        goBack() {
            this.$router.go(-1)
        },
        upLoadIcon(e) {
			if(e.file) {
				const file = e.file;
				let formData = new FormData();
				formData.append("file",file);
				service.home.userUpload(formData).then(res => {
                    this.newProject.imageUrl=`/userupload/${ res.data.filename }`;
					this.newProject.iconName = res.data.filename;
					this.$message.success("アイコンが正常にアップロードされました")
				}).catch(()=>{
					this.$message.error("アイコンのアップロードに失敗しました")
				})
			}
		},
        upLoadBook(e) {
            this.newProject.fileName = e.file.name
            const file = e.file;
            let formData = new FormData();
            formData.append("file",file);
           
            service.personal.projectUpload(formData).then(res => {
                if(res.status == 3) {
                    this.$alert('同じなファイル名が既に存在しているため、ファイル名を再度作ってください。', '警告', {
                    confirmButtonText: '確定',
                    callback: action => {
                    this.newProject.fileName =  this.projectInfo.proposals || ''
                    }
                });
                // this.$confirm('同じなファイル名が既に存在しているため、ファイル名を再度作ってください。', '警告', {
                
                // cancelButtonText: '確定',
                // type: 'warning'
                // }).then(() => {
            
                // }).catch(() => {
                        
                // });
                }
                if(res.status == 200) {
                    this.newProject.bookName = res.data.filename;
                }

            }).catch(err => {
                console.log(err)
            })
        },
        getTypeData() {
            service.personal.getTypeData().then( res => {
               this.newProject.btype = res.data.data
            })
        },
        creatProject(data) {
            service.personal.creatProject(data).then(res => {
                if(res.status === 200) {
                    this.$message.success("プロジェクトが作成されました");
                    this.$router.go(-1)
                }
            })
        },
        confirm() {
            console.log(this.newProject.btype,"===", this.newProject.bval)
            if(!this.newProject.name || !this.newProject.bval || !this.newProject.outline) {
                this.$message.warning("必須項目が全て入力されたことを確認して下さい")
            } else {
                this.outline = this.newProject.outline.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>').replace(/\s/g, '&nbsp;');
               
                this.dialogFormVisible = true
            }
        },
        cancel() {
            this.$parent.status = false;
        },
        filterClassAType(id) {
            // console.log(id)
            let label = "";
            this.newProject.btype.forEach((item) => {
                if (id == item.id) label = item.typeb;
            });
             return label;
        },
        submitData() {
            this.dialogFormVisible = false;
            var content = this.newProject.outline.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;');
            this.outline = content
            console.log(content); 
            let obj = {
                title: this.newProject.name,
                type: this.newProject.cartoonType,
                typeb: this.newProject.bval.join(','),
                creativecap: this.newProject.isMake,
                content: content,
                proposals: this.newProject.bookName,
                kind: this.newProject.makeType,
                public: this.newProject.isOvert,
                icon: this.newProject.iconName,
                projectid: this.projectId
            }
           this.creatProject(obj)
        //    if(!this.isEdit) {
        //         this.creatProject(obj)
        //    } else {
        //     alert("需调修改接口")
        //    }
        }
    }
}

</script>
<style lang="scss" scoped>
    .main {
        padding: 20px;
        .title {
            color: #333;
            font-size: 16px;
            font-weight: 500;
        }
        .form {
            margin-top: 30px;
            ::v-deep .avatar-uploader .el-upload {
                border: 1px solid #999;
                border-radius: 4px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                width: 60px;
                height: 60px;
                background: #f4f4f4;
            }
            .avatar {
                width: 178px;
                height: 178px;
                display: block;
            }
            .avatar-uploader-icon {
                font-size: 20px;
                color: #8c939d;
                line-height: 60px;
            }
        }
        .submit {
            display: flex;
            justify-content: center;
            height: 100px;
            align-items: center;
            padding-bottom: 70px;
            span:first-child {
                display: block;
                width: 200px;
                height: 50px;
                line-height: 50px;
                background: #F03747;
                border-radius: 8px;
                color: #fff;
                text-align: center;
                cursor: pointer;
            }
            // span:last-child {
            //     display: block;
            //     width: 200px;
            //     height: 50px;
            //     line-height: 50px;
            //     border: 1px solid #909399;
            //     color: #fff;
            //     margin: 0 20px;
            //     border-radius: 8px;
            //     background-color: #909399;
            //     text-align: center;
            //     cursor: pointer;
            // }
        }
    }
</style>